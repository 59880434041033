<template>
    <section>
        <header class="mb-2">
          <b-col sm="12" md="12">
      <b-card-actions action-collapse :title="'Jurnal'">
        <b-row>
          <b-col>
          <validation-observer ref="formakun">
            <b-form>
              <b-row>
                <b-col sm="12" md="6">
                  <b-form-group label="Jenis Transaksi">
                    <b-form-select
                      v-model="transactionType"
                      :options="[{value: 1, text: 'Debit'}, {value: 2, text: 'Kredit'}]"
                      id="v-transaction_type"
                      name="transaction_type"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6"></b-col>
                <b-col sm="12" md="6" v-if="transactionType">
                  <b-form-group
                  label="Pilih Akun"
                  >
                    <!-- label-for="v-id_akun" -->
                    <validation-provider
                    #default="{ errors }"
                      rules="required"
                      name="id_akun"
                    >
                      <b-form-select
                        v-model="form.id_akun"
                        :options="id_akun"
                        id="v-id_akun"
                        name="id_akun"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col sm="12" md="6" v-if="transactionType">
                  <b-form-group
                  label="Pilih Kas"
                  >
                    <!-- label-for="v-id_kas" -->
                    <validation-provider
                    #default="{ errors }"
                      rules="required"
                      name="id_kas"
                    >
                      <b-form-select
                        v-model="form.id_kas"
                        :options="id_kas"
                        id="v-id_kas"
                        name="id_kas"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                <b-form-group label="Tanggal & Waktu" label-for="v-tanggal">
                  <validation-provider
                    #default="{ errors }"
                    name="tanggal"
                    :rules="{ required: true }"
                  >
                    <flat-pickr
                      v-model="form.tanggal"
                      class="form-control"
                      :config="{ enableTime: true, dateFormat: 'Y-m-d' }"
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
                <!-- Keterangan -->
                <b-col cols="6">
                  <b-form-group
                    label="Keterangan"
                    label-for="v-keterangan"
                  >
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      name="kterangan"
                    >
                      <b-form-input
                        v-model="form.keterangan"
                        id="v-keterangan"
                        placeholder="Isi keterangan"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- Debit -->
                <b-col cols="6" v-if="isDebitAkun">
                  <b-form-group
                    label="Debit"
                    label-for="v-debit"
                  >
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      name="debit"
                    >
                      <b-form-input
                        :state="errors.length > 0 ? false : null"
                        v-model="form.debit"
                        id="v-debit"
                        placeholder="Isi "
                        @keyup="form.debit = formatRupiah(form.debit)"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- Kredit -->
                <b-col cols="6" v-if="isKreditAkun">
                  <b-form-group
                    label="Kredit"
                    label-for="v-kredit"
                  >
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      name="kredit"
                    >
                      <b-form-input
                        :state="errors.length > 0 ? false : null"
                        v-model="form.kredit"
                        id="v-kredit"
                        placeholder="Isi Kredit"
                        @keyup="form.kredit = formatRupiah(form.kredit)"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                

                <!-- submit and reset -->
                <b-col cols="12" class="d-flex justify-content-end">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="submit"
                    variant="primary"
                    class="mr-1"
                    @click.prevent="submit"
                    >
                    Tambah
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-col>
        </b-row>
      </b-card-actions>
      </b-col>
    <!-- </b-col>
  </b-row> -->
  
</header>
</section>
<!-- </b-col> -->
<!-- </main> -->
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, confirmed, password } from "@validations";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";
import "@core/scss/vue/libs/vue-flatpicker.scss";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import {
  BTable,
  VBTooltip,
  BFormDatepicker,
  BFormTimepicker,
  BAvatar,
  BBadge,
  BModal,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BFormRadio,
  BButton,
  BFormTextarea,
  BCardText,
} from "bootstrap-vue";
import vSelect from "vue-select";

export default {
  components: {
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    BCardActions,
    VBTooltip,
    BFormTimepicker,
    BForm,
    BModal,
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BFormTextarea,
    BFormDatepicker,
    BFormRadio,
    BCardText,
    vSelect,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  watch: {
    transactionType(val) {
      if(val) {
        if(val == 1) {
          this.id_akun = this.debitAkun
        }
        else {
          this.id_akun = this.kreditAkun
        }
      }
    }
  },
  data() {
    return {
      debitAkun: [],
      kreditAkun: [],
      transactionType: null,
      showModal: false,
      required,
      password,
      email,
      confirmed,
      form: {
        id_akun: null,
        id_kas: null,
        tanggal: this.getCurrentDate(),
        modul: "jurnal",
        debit: 0,
        kredit: 0,
        keterangan: null,
        // saldo: null,
      },
      jenis: [
        { value: 1, text: "Masuk" },
        { value: 2, text: "Keluar" },
      ],
      id_kas: [],
      id_akun: [],
      userData: JSON.parse(localStorage.getItem("userData")),
      perPage: 100,
      pageOptions: [20, 50, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      detailmodal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        { key: "no", label: "no" },
        // { key: "id", label: "Id"},
        { key: "debit", label: "Kode Akun", sortable: true },
        { key: "keterangan", label: "keterangan Akun", sortable: true },
        { key: "kategori.kategori", label: "kategori", sortable: true },
        { key: "kredit", label: "kredit", sortable: true },
        { key: "saldo", label: "saldo", sortable: true },
        { key: "actions", label: "Aksi" },
      ],
      items: [],
      status: [
        {
          1: "Belum",
          2: "Approve SPV",
          3: "Approve Gudang",
          4: "Sudah Ambil",
        },
        {
          1: "light-danger",
          2: "light-info",
          3: "light-warning",
          4: "light-success",
        },
      ],
      jns: [
        {
          1: "Masuk",
          2: "Keluar",
        },
        {
          1: "light-info",
          2: "light-danger",
        },
      ],
    };
  },
  computed: {
    isKreditAkun() {
      return this.transactionType == 2
    },
    isDebitAkun() {
      return this.transactionType == 1
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  created() {
  },
  mounted() {
    this.getKas();
    this.getkategori();
  },
  methods: {
    addJurnal(){

    },
    onContext(ctx) {
      // The date formatted in the locale, or the `label-no - date - selected` string
      this.formatted = ctx.selectedFormatted;
      // The following will be an empty string until a valid date is entered
      this.selected = ctx.selectedYMD;
    },
    edit(item) {
      this.form = item;
      this.id = item.id;
      this.showModal = true;
    },
    add() {
      this.resetForm();
      this.showModal = true;
      // const userData = JSON.parse(localStorage.getItem("userData"));
      // if (userData.id) {
      //   this.form.id_akun = userData.karyawan.id;
      //   this.form.id_kas = this.$route.params.id;
      // }
    },
    remove(item) {
      this.$swal({
        title: "Anda yakin?",
        text: `Data Akun "${item.keterangan}" ini akan dihapus`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          item.fungsi = 1; // soft delete
          this.$store
            .dispatch("akun/save", [item])
            .then(() => {
              this.getKas();
              // this.pesanBerhasilHapus();
              this.displaySuccess({
                message: "Data Akun berhasil terhapus"
              });
              this.resetForm();
            })
            .catch((e) => {
              this.displayError(e);
              return false;
            });
        }
      });
    },
    resetForm() {
      this.id = null;
      this.form = {
        id_akun: null,
        id_kas: null,
        tanggal: this.getCurrentDate(),
        modul: "jurnal",
        debit: 0,
        kredit: 0,
        keterangan: null
      };
    },
    submit() {
      this.$refs.formakun.validate().then((success) => {
        if (success) {
          this.label = "Loading...";
          if (this.id) {
            this.form.id = this.id;
          }
          this.form.debit = this.unFormatRupiah(this.form.debit)
          this.form.kredit = this.unFormatRupiah(this.form.kredit)
          const payload = this.form;
          this.$store
            .dispatch("jurnal/save", [payload])
            .then(() => {
              this.label = "Submit";
              this.resetForm();
              this.displaySuccess({
                message: "Data Jurnal berhasil disimpan"
              });
              this.showModal = false;
              this.getKas();
            })
            .catch((e) => {
              this.label = "Submit";
              this.displayError(e);
              return false;
            });
        }
      });
    },
    async getKas() {
      this.$store
        .dispatch("kas/getData", {})
        .then(() => {
          let hem = JSON.parse(
            JSON.stringify(this.$store.state.kas.datas)
          );
          hem.map((item) => {
            item.value = item.id;
            item.text = item.saldo >= 0 ? item.keterangan + " -> " + this.formatRupiah(item.saldo) : item.keterangan + "( " + this.formatRupiah(item.saldo * -1) + " )";
          });
          this.id_kas = hem;
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
    getkategori() {
      this.$store
        .dispatch("akun/getData", {})
        .then(() => {
          let akuns = JSON.parse(
            JSON.stringify(this.$store.state.akun.datas)
          );
          akuns.map((item) => {
            item.value = item.id
            item.text = item.saldo >= 0 ? item.nama + " -> " + this.formatRupiah(item.saldo) : item.nama + "( " + this.formatRupiah(item.saldo * -1) + " )";
          });

          this.debitAkun = akuns.filter(akun => [this.JENIS_AKUN.debit, this.JENIS_AKUN.debit_kredit].includes(akun.jenis))
          this.kreditAkun = akuns.filter(akun => [this.JENIS_AKUN.kredit, this.JENIS_AKUN.debit_kredit].includes(akun.jenis))
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    dateDisabled(ymd, date) {
      // Disable weekends (Sunday = `0`, Saturday = `6`) and
      // disable days that fall on the 13th of the month
      const weekday = date.getDay();
      const day = date.getDate();
      // Return `true` if the date should be disabled
      return weekday === 0 || weekday === 6 || day === 13;
    },
  },
};
</script>
